<template>
    <div class="equalizer_sub_wrap">
        <div class="equalizer_bar fisrt"></div>
        <div class="equalizer_bar second"></div>
        <div class="equalizer_bar third"></div>
        <div class="equalizer_bar fourth"></div>
        <div class="equalizer_bar fifth"></div>
    </div>
</template>
<script>
export default {
  name: 'Equalizer',

  data () {
    return {};
  },

  mounted () {},

  methods: {}
};
</script>
<style scoped>
.equalizer_sub_wrap {
  height: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 19px;
  justify-content: space-between;
}

.equalizer_bar {
  width: 2px;
  border-radius: 50px;
  border: none !important;
  padding: 0 !important;
  background: linear-gradient(180deg, #fff, #ed4a9f);
  -webkit-animation: rythm 1s linear infinite;
  animation: rythm 1s linear infinite;
}

.equalizer_bar.fisrt {
  height: 23px;
  animation: rythm 1s linear infinite;
  animation-delay: 0.1s;
}

.equalizer_bar.second {
  height: 21px;
  animation: rythm 1s linear infinite;
  animation-delay: 0.2s;
}

.equalizer_bar.third {
  height: 23px;
  animation: rythm 1s linear infinite;
  animation-delay: 0.4s;
}

.equalizer_bar.fourth {
  height: 21px;
  animation: rythm 1s linear infinite;
  animation-delay: 0.2s;
}

.equalizer_bar.fifth {
  height: 23px;
  animation: rythm 1s linear infinite;
  animation-delay: 0.1s;
}

@keyframes rythm {
  0% {
    transform: scaleY(100%);
  }

  50% {
    transform: scaleY(30%);
  }

  100% {
    transform: scaleY(100%);
  }
}
</style>
